<template>
  <div>
    <div class="top-stats">
      <el-row :gutter="30" style="margin-bottom: 20px;">
        <el-col :span="4">
          <el-card shadow="always">
            <h3>{{ outletCount.total }}</h3>
            <div class="clearfix" @click="filterGet('all', 'all')">
              <b>Нийт бүтээгдэхүүн</b>
              <el-button
                style="float: right; padding: 3px 0; color: gray"
                type="text"
                ><i class="el-icon-finished"></i
              ></el-button>
            </div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="always">
            <h3>{{ outletCount.is_activeCount }}</h3>
            <div class="clearfix" @click="filterGet('true', 'all')">
              <b>Идэвхитэй бүтээгдэхүүн</b>
              <el-button
                style="float: right; padding: 3px 0; color: gray"
                type="text"
                ><i class="el-icon-food"></i
              ></el-button>
            </div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="always">
            <h3>{{ outletCount.is_deActiveCount }}</h3>
            <div class="clearfix" @click="filterGet('false', 'all')">
              <b>Идэвхигүй бүтээгдэхүүн</b>
              <el-button
                style="float: right; padding: 3px 0; color: gray"
                type="text"
                ><i class="el-icon-dish"></i
              ></el-button>
            </div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="always">
            <h3>{{ outletCount.is_draftCount }}</h3>
            <div class="clearfix" @click="filterGet('all', 'true')">
              <b>Апп дээр нуусан</b>
              <el-button
                style="float: right; padding: 3px 0; color: gray"
                type="text"
                ><i class="el-icon-open"></i
              ></el-button>
            </div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="always">
            <h3>{{ outletCount.is_undraftCount }}</h3>
            <div class="clearfix" @click="filterGet('all', 'false')">
              <b>Апп дээр нуугаагүй</b>
              <el-button
                style="float: right; padding: 3px 0; color: gray"
                type="text"
                ><i class="el-icon-turn-off"></i
              ></el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="page-header panel">
      <h3 class="title">
        <a @click="$router.go(-1)" class="back-button"
          ><i class="el-icon-back"></i
        ></a>
        {{ outlet.full_name_mon }}
      </h3>
      <div class="action-section">
        <router-link
          :to="{
            path: 'partner-info',
            query: { _id: $route.query.outlet_id }
          }"
        >
          <el-button type="info" size="mini" icon="el-icon-top-left"
            >Рестораны мэдээлэл засах</el-button
          >
        </router-link>
        <el-button
          v-if="
            mArrayIncludes(role, [
              'ST',
              'SM',
              'admin',
              'client_care',
              'finance'
            ])
          "
          type="info"
          style="margin-left:20px"
          size="mini"
          icon="el-icon-download"
          @click="onexport()"
          >Татаж авах (.xlsx)</el-button
        >
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="panel">
          <el-input
            v-model="search"
            size="mini"
            prefix-icon="el-icon-search"
            style="width:20%"
            placeholder="Хайх нэрээ оруулна уу"
          />
          <el-table
            ref="filterTable"
            :data="
              tableData.filter(
                data =>
                  !search ||
                  data.name_mon.toLowerCase().includes(search.toLowerCase())
              )
            "
          >
            <el-table-column label="Д/д" type="index" width="40">
            </el-table-column>
            <el-table-column prop="name_mon" label="Нэр"> </el-table-column>
            <el-table-column
              prop="name_eng"
              label="Англи нэр"
            ></el-table-column>
            <el-table-column prop="menu_price" label="Үнэ">
              <template slot-scope="scope">
                {{ mformatPrice(scope.row.menu_price) }}
              </template>
            </el-table-column>
            <el-table-column prop="menu_sort" label="Эрэмбэ" width="100px">
            </el-table-column>
            <el-table-column
              prop="menu_category_mon"
              label="Ангилал"
              :filters="filters"
              :filter-method="filterTag"
            >
            </el-table-column>
            <el-table-column prop="category_mon" label="Үндсэн ангилал">
              <template slot-scope="scope">
                <div v-if="scope.row.category_mon === 'Түр гарахгүй'">
                  <li>{{ scope.row.category_mon }}</li>
                </div>
                <div v-if="scope.row.category_mon !== 'Түр гарахгүй'">
                  <li v-for="i in scope.row.category_mon" size="mini" :key="i">
                    {{ i }}
                  </li>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :filters="[
                { text: 'Идэвхгүй', value: false },
                { text: 'Идэвхтэй', value: true }
              ]"
              :filter-method="isActiveFil"
              prop="is_active"
              label="Төлөв"
              width="90px"
            >
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <el-tag
                    v-if="scope.row.is_active"
                    size="medium"
                    type="success"
                    ><i class="el-icon-open"></i
                  ></el-tag>
                  <el-tag v-else size="medium" type="danger"
                    ><i class="el-icon-turn-off"></i
                  ></el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="img_url" label="Зураг" width="100px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <el-image
                    style="width: 20px; height: 20px"
                    :src="scope.row.img_url"
                  ></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="zip"
              label="Үйлдэл"
              width="120px"
              v-if="!mArrayIncludes(role, ['finance', 'other'])"
            >
              <template slot-scope="scope">
                <el-button
                  :type="scope.row.is_active ? 'warning' : 'primary'"
                  size="mini"
                  @click="onClickEmployeeOpenCloseMenu(scope.row)"
                >
                  {{ scope.row.is_active ? "Хаах" : "Нээх" }}
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  circle
                  @click="edit(scope.row._id)"
                  icon="el-icon-edit"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care'
                ])
              "
              prop="zip"
              label="Хуулах"
              width="100px"
            >
              <template slot-scope="scope">
                <el-button
                  class="partner-button"
                  type="success"
                  size="mini"
                  icon="el-icon-copy-document"
                  @click="copy(scope.row)"
                  disabled
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Түүх" width="150px">
              <template slot-scope="scope">
                <el-button
                  type="info"
                  size="mini"
                  @click="log(scope.row._id)"
                  icon="el-icon-timer"
                  >Түүх харах</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care'
                ])
              "
              label="Устгах"
              width="100px"
              :filters="[
                { text: 'Устгах', value: false },
                { text: 'Сэргээх', value: true }
              ]"
              :filter-method="actionFilterTag"
              filter-placement="bottom-end"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="!scope.row.deleted"
                  type="warning"
                  size="mini"
                  @click="deleteMenu(scope.row._id)"
                  icon="el-icon-delete"
                  >Устгах</el-button
                >
                <el-button
                  v-else-if="scope.row.deleted"
                  type="success"
                  size="mini"
                  @click="restoreMenu(scope.row._id)"
                  icon="el-icon-refresh-left"
                  >Сэргээх</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            title="Түүх харах"
            :visible.sync="dialogBool"
            width="60%"
            center
          >
            <span>
              <el-table :data="logs" size="mini" style="width: 95%">
                <el-table-column prop="action" label="Үйлдэл">
                </el-table-column>
                <el-table-column prop="desc" label="Тайлбар"> </el-table-column>
                <el-table-column prop="user" label="Хэн"> </el-table-column>
                <el-table-column
                  prop="created_at"
                  label="Хэзээ"
                  width="90"
                  sortable
                >
                </el-table-column>
                <el-table-column prop="old.menu_price" label="Хуучин үнэ">
                </el-table-column>
                <el-table-column prop="new.menu_price" label="Шинэ үнэ">
                </el-table-column>
                <el-table-column prop="old.name_mon" label="Хуучин нэр">
                </el-table-column>
                <el-table-column prop="new.name_mon" label="Шинэ нэр">
                </el-table-column>
                <el-table-column prop="old.is_active" label="Хуучин төлөв">
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <el-tag
                        v-if="scope.row.old.is_active"
                        size="mini"
                        type="success"
                        >Хэвийн</el-tag
                      >
                      <el-tag v-else size="mini" type="danger">Хаасан</el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="new.is_active" label="Шинэ төлөв">
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <el-tag
                        v-if="scope.row.new.is_active"
                        size="mini"
                        type="success"
                        >Хэвийн</el-tag
                      >
                      <el-tag v-else size="mini" type="danger">Хаасан</el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="new.is_draft"
                  label="Нуусан эсэх /хуучин/"
                >
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <el-tag
                        v-if="scope.row.old.is_draft"
                        size="mini"
                        type="danger"
                        >Нуусан</el-tag
                      >
                      <el-tag v-else size="mini" type="success"
                        >нуугаагүй</el-tag
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="new.is_draft" label="Нуусан эсэх /шинэ/">
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <el-tag
                        v-if="scope.row.new.is_draft"
                        size="mini"
                        type="danger"
                        >Нуусан</el-tag
                      >
                      <el-tag v-else size="mini" type="success"
                        >нуугаагүй</el-tag
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </span>
            <el-pagination
              background
              class="text-right"
              @size-change="logSizeChangeHandler"
              layout="total, sizes, prev, pager, next"
              :total="pageCount"
              :page-size="size"
              :page-sizes="[10, 20, 30, 50, 100]"
              @current-change="logSetPage"
            >
            </el-pagination>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogBool = false" type="danger"
                >Хаах</el-button
              >
            </span>
          </el-dialog>
          <el-dialog
            title="Бүтээгдэхүүн хуулах"
            :visible.sync="copyDialog"
            :before-close="handleClose"
          >
            <el-form
              ref="copyForm"
              :model="copyProd"
              label-width="150px"
              :rules="copyFormRules"
            >
              <el-form-item label="Монгол нэр">
                <el-input v-model="copyProd.name_mon" disabled></el-input>
              </el-form-item>
              <el-form-item label="Англи нэр">
                <el-input v-model="copyProd.name_eng" disabled></el-input>
              </el-form-item>
              <el-form-item label="Харилцагчийн нэр " prop="outlet">
                <el-select v-model="copyProd.outlet" filterable value-key="_id">
                  <el-option
                    v-for="parent in parent_outlet_name"
                    :label="parent.full_name_eng"
                    :value="{
                      outlet_name: parent.full_name_eng,
                      _id: parent._id
                    }"
                    :key="parent._id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Үйлчилгээ" prop="service">
                <el-select
                  placeholder="Үйлчилгээ"
                  v-model="copyProd.service"
                  @change="$forceUpdate()"
                >
                  <el-option
                    value="food13"
                    key="food13"
                    label="тест"
                  ></el-option>
                  <el-option
                    v-for="(service, index) in servicesList"
                    :label="service.name_mon"
                    :value="service.name_eng"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="handleClose">Буцах</el-button>
              <el-button type="primary" @click="copyReq">Хуулах</el-button>
            </span>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { getUserName, getGroups } from "../utils/auth";
import { formatPrice, arrayIncludes } from "../helpers/helper";
import XLSX from "xlsx";
export default {
  created() {
    if (this.$route.query.outlet_id !== null) {
      this.getMenu(this.$route.query.outlet_id);
    }
    getGroups().then(data => {
      this.role = data;
    });
    this.getServices();
    const payload = {
      includeFields: ["full_name_eng"],
      is_verified: true
    };
    service.getOutlets(payload).then(data => {
      this.parent_outlet_name = data.data.data;
    });
  },
  data() {
    return {
      currentPage: 1,
      pageItemLimit: 10,
      pageCount: 0,
      is_active: "all",
      is_draft: "all",
      dialogBool: false,
      value1: true,
      outlet: {},
      logs: [],
      filters: [],
      tableData: [],
      downloadMenuDataPage: [],
      outletCount: {},
      role: [],
      search: "",
      copyDialog: false,
      copyProd: {},
      copyFormRules: {
        service: [
          {
            required: true,
            message: "категори сонгоогүй байна .",
            trigger: "blur"
          }
        ],
        outlet_name: [
          {
            required: true,
            message: "Харилцагчийн нэр оруулаагүй байна.",
            trigger: "blur"
          }
        ]
      },
      servicesList: [],
      parent_outlet_name: [],
      size: 10,
      from: 0,
      logTitle: ""
    };
  },
  methods: {
    isActiveFil(value, row) {
      return row.is_active === value;
    },
    onexport() {
      let reportXlsx = XLSX.utils.json_to_sheet(this.downloadMenuDataPage);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, reportXlsx, "report");
      XLSX.writeFile(wb, this.downloadMenuDataPage.outlet_name + ".xlsx");
    },
    getCurrentPageData() {
      let data = [];
      if (this.logs.length > 0) {
        data = this.logs.slice(
          (this.currentPage - 1) * this.pageItemLimit,
          this.pageItemLimit * this.currentPage
        );
      } else {
        data = [];
      }
      return data;
    },
    changePage(a) {
      this.currentPage = parseInt(a);
    },
    actionFilterTag(value, row) {
      return row.deleted === value;
    },
    filterGet(is_active, is_draft) {
      this.is_active = is_active;
      this.is_draft = is_draft;
      this.getMenu(this.$route.query.outlet_id);
    },
    deleteMenu(id) {
      this.$confirm(
        "This will permanently delete the file. Continue?",
        "Анхааруулга",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          service.deleteMenu(id).then(data => {
            if (data.data.data.status === "updated") {
              this.getMenu(this.$route.query.outlet_id);
              this.$message({
                type: "success",
                message: "Амжилттай "
              });
            } else {
              this.$message({
                type: "success",
                message: data.data.data.error
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
    restoreMenu(id) {
      this.$confirm("Энэ барааг сэргээхдээ итгэлтэй байна уу?", "Анхааруулга", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          getUserName().then(name => {
            let payload = {
              _id: id,
              desc: "hooson bn"
            };
            payload.user = name.attributes.email.split("@")[0];
            service.restoreMenu(payload).then(data => {
              this.getMenu(this.$route.query.outlet_id);
              this.$message({
                type: "success",
                message: data.result
              });
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "restore canceled"
          });
        });
    },
    log(id) {
      this.logTitle = id;
      service
        .getMenuLog(this.size, this.from, id)
        .then(logRes => {
          this.dialogBool = true;
          this.logs = logRes.data.data.sort(function(a, b) {
            let c = new Date(a.created_at);
            let d = new Date(b.created_at);
            return d - c;
          });
          this.pageCount = logRes.data.total;
        })
        .catch(er => {
          this.$notify({
            title: "Анхааруулга",
            message: "Алдаа түүх харах: " + er.message,
            type: "warning"
          });
        });
    },
    filterTag(value, row) {
      return row.menu_categories.menu_category_mon === value;
    },
    edit(_id) {
      this.$router.push({
        path: "edit-menu",
        query: { menu_id: _id, outlet_id: this.$route.query.outlet_id }
      });
    },
    add() {
      this.$router.push({
        path: "add-menu",
        query: { outlet_id: this.$route.query.outlet_id }
      });
    },
    getMenu(outlet_id) {
      this.filters = [];
      const payload = { includeFields: [] };
      service.getOutletById(outlet_id, payload).then(outletResponse => {
        this.outlet = outletResponse.data.data;
        outletResponse.data.data.menu_categories.forEach(element => {
          this.filters.push({
            text: element.menu_category_mon,
            value: element.menu_category_mon
          });
        });
        let temp = {
          outlet_name: this.outlet.outlet_name,
          outlet_id: outlet_id
        };
        if (this.is_active !== "all") {
          if (this.is_active === "true") {
            temp.is_active = true;
          } else {
            temp.is_active = false;
          }
        } else this.is_active = null;
        if (this.is_draft !== "all") {
          if (this.is_draft === "true") {
            temp.is_draft = true;
          } else {
            temp.is_draft = false;
          }
        } else this.is_draft = null;
        service
          .getMenusByOutlet(outlet_id, this.is_draft, this.is_active)
          .then(menuResponse => {
            this.tableData = menuResponse.data.data;
            let downloadMenuData = [];
            this.tableData.forEach(function(item) {
              let dataItem = {
                outlet_name: "",
                name_eng: "",
                desc_eng: "",
                menu_category_mon: "",
                menu_category: "",
                menu_price: "",
                name_mon: "",
                menu_category_eng: "",
                status_mon: "",
                service: "",
                desc_mon: ""
              };
              dataItem.outlet_name = item.outlet_name;
              dataItem.name_eng = item.name_eng;
              dataItem.desc_eng = item.desc_eng;
              dataItem.menu_category_mon = item.menu_category_mon;
              dataItem.menu_category = item.menu_category;
              dataItem.menu_price = item.menu_price;
              dataItem.name_mon = item.name_mon;
              dataItem.menu_category_eng = item.menu_category_eng;
              dataItem.status_mon = item.status_mon;
              dataItem.service = item.service;
              dataItem.desc_mon = item.desc_mon;
              downloadMenuData.push(dataItem);
            });
            this.downloadMenuDataPage = downloadMenuData;
          });
        service.productDashboard(this.$route.query.outlet_id).then(response => {
          this.outletCount.total = response.data.data.total;
          this.outletCount.is_activeCount = response.data.data.active;
          this.outletCount.is_deActiveCount = response.data.data.inActive;
          this.outletCount.is_draftCount = response.data.data.draft;
          this.outletCount.is_undraftCount = response.data.data.unDraft;
        });
      });
    },
    onClickEmployeeOpenCloseMenu(data) {
      let payload = JSON.parse(JSON.stringify(data));
      getUserName().then(name => {
        payload.user = name.attributes.email.split("@")[0];
        payload.is_active = payload.is_active ? false : true;
        payload.id = payload._id;
        service.updateMenu(payload).then(response => {
          this.$notify({
            title: "Амжилттай",
            message: response.message,
            type: "success"
          });
          this.getMenu(this.$route.query.outlet_id);
        });
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    mformatPrice(value) {
      return formatPrice(value);
    },
    copyReq() {
      this.$refs.copyForm.validate(async valid => {
        if (valid) {
          getUserName().then(name => {
            this.copyProd.user = name.attributes.email.split("@")[0];
          });
          let payload = JSON.parse(JSON.stringify(this.copyProd));
          payload.user = (await getUserName()).attributes.email.split("@")[0];
          service.copyProduct(this.copyProd).then(response => {
            if (response.status === "created") {
              this.$notify({
                title: "Амжилттай",
                type: "success"
              });
            } else {
              this.$notify({
                title: "Алдаа",
                message: response.message,
                type: "error"
              });
            }
            this.copyProd = {};
            this.copyDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    copy(data) {
      this.copyProd.id = data._id;
      this.copyProd.name_mon = data.name_mon;
      this.copyProd.name_eng = data.name_eng;
      this.copyDialog = true;
    },
    handleClose(done) {
      this.$confirm("Бүтээгдэхүүн Хуулахаа болих !!!")
        .then(ok => {
          this.copyProd = {};
          this.copyDialog = false;
          ok === "" ? done() : done();
        })
        .catch(er => {
          this.$notify({
            title: "Error",
            message: er.message,
            type: "warning"
          });
        });
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    logSizeChangeHandler(size) {
      this.size = size;
      this.log(this.logTitle);
    },
    logSetPage(page) {
      this.from = (page - 1) * this.size;
      this.log(this.logTitle);
    }
  }
};
</script>
<style>
.top-stats h3 {
  align-items: center;
}
</style>
